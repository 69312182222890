import React, { useEffect, useRef } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
//
import './ImageCarousel.scss';

export default function ImageCarousel({ carouselItems }) {
  const swiper = useRef();

  useEffect(() => {
    swiper.current = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 20,
      centeredSlides: true,
      breakpoints: {
        1024: {
          spaceBetween: 40,
        },
      },
      loop: true,
      slideToClickedSlide: true,
    });
  }, []);

  const renderSlides = () => {
    return carouselItems.map((item, index) => {
      return (
        <div className='swiper-slide' key={index}>
          <img src={item.url} alt={item.altText} />
        </div>
      );
    });
  };

  const handlePaginate = direction => {
    if (direction === 'left') {
      swiper.current.slidePrev();
    }

    if (direction === 'right') {
      swiper.current.slideNext();
    }
  };

  return (
    <div className='ImageCarousel'>
      <div className='swiper-container'>
        <div className='swiper-wrapper'>{renderSlides()}</div>
      </div>
      <div className='controls'>
        <button
          className='arrow left'
          aria-label='Paginate left'
          onClick={() => handlePaginate('left')}
        />
        <button
          className='arrow right'
          aria-label='Paginate right'
          onClick={() => handlePaginate('right')}
        />
      </div>
    </div>
  );
}
