import React, { useState, useEffect, useRef } from 'react';
//
import './../css/index.scss';
import './LandingPage.scss';
import Head from './../components/head/Head';
import { Cell, Grid, GridContainer } from './../components/grid';
import { Button, ButtonConstants } from './../components/button';
import ImageCarousel from './../components/imageCarousel/ImageCarousel';
import Nav from './../components/nav/Nav';
import Footer from './../components/footer/Footer';
import ARHero from '../components/arHero/ARHero';

export default function LandingPage({ pageContext }) {
  const [showPlayer, setShowPlayer] = useState(false);
  const playerContainerRef = useRef();
  const [downArrowStyle, setDownArrowStyle] = useState({});
  let player;

  const carouselItems = [
    {
      url: '/img/VolunteerCarousel_Image_1.jpg',
      altText: 'Man reading book to class',
    },
    {
      url: '/img/VolunteerCarousel_Image_2.jpg',
      altText: 'Image of Planet Zeee and the Money Tree',
    },
    {
      url: '/img/VolunteerCarousel_Image_3.jpg',
      altText: 'Woman with child holding book',
    },
    {
      url: '/img/VolunteerCarousel_Image_4.jpg',
      altText: 'Man reading book to class',
    },
    {
      url: '/img/VolunteerCarousel_Image_5.jpg',
      altText: 'Child holding book',
    },
    {
      url: '/img/VolunteerCarousel_Image_6.jpg',
      altText: 'Kids having fun in class',
    },
    {
      url: '/img/VolunteerCarousel_Image_7.jpg',
      altText: 'Man showing tablet to kids',
    },
    {
      url: '/img/VolunteerCarousel_Image_8.jpg',
      altText: 'Man reading book to class',
    },
    {
      url: '/img/VolunteerCarousel_Image_9.jpg',
      altText: 'Woman with child holding book',
    },
    {
      url: '/img/VolunteerCarousel_Image_11.jpg',
      altText: 'Volunteers interacting with kids',
    },
    {
      url: '/img/VolunteerCarousel_Image_13.jpg',
      altText: 'Volunteers with kids holding books',
    },
    {
      url: '/img/VolunteerCarousel_Image_14.jpg',
      altText: 'Woman reading book to class',
    },
    {
      url: '/img/VolunteerCarousel_Image_15.jpg',
      altText: 'Woman teaching a class',
    },
    {
      url: '/img/VolunteerCarousel_Image_16.jpg',
      altText: 'Group photo of volunteers and kids',
    },
  ];

  useEffect(() => {
    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      window.onYouTubeIframeAPIReady = initVideo;
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    } else {
      initVideo();
    }

    if (typeof window !== 'undefined')
      setDownArrowStyle({
        top: `${window.innerHeight - 70}px`,
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initVideo = () => {
    // eslint-disable-next-line no-undef
    player = new window.YT.Player(playerContainerRef.current, {
      videoId: 'hR80r0zUORk',
    });
  };

  const playVideo = () => {
    setShowPlayer(true);
    if (player) {
      player.playVideo();
    }
  };

  return (
    <div className='LandingPage'>
      <Head {...pageContext.seoMetadata} />
      <GridContainer>
        <Grid>
          <Cell small={12} collapse>
            <Nav />
            <main>
              <div className='hero'>
                <Grid>
                  <Cell className='hero-title' small={12} large={5}>
                    <h1 className='color-text-plum'>
                      Adventures with money
                      <span className='color-text-grapefruit'>.</span>
                    </h1>
                  </Cell>
                </Grid>
                <Grid>
                  <Cell className='hero-description' small={12} large={5}>
                    <p className='text-subhead'>
                      It's never too early to start teaching kids to build good
                      money habits with books and games that make learning fun.
                    </p>
                  </Cell>
                </Grid>
                <a className='down-arrow' href='#books' style={downArrowStyle}>
                  <img src='/img/down-arrow.png' alt='Down arrow' />
                </a>
              </div>
              <div id='books' className='books color-background-champagne'>
                <Grid className='book-item reverse'>
                  <Cell className='book-image' small={12} large={6}>
                    <img
                      className='book'
                      src={'/img/Book_Planet_Zeee_And_The_Money_Tree.png'}
                      alt='Planet Zeee and the Money Tree book'
                    />
                  </Cell>
                  <Cell className='book-info' small={12} large={6}>
                    <h2 className='book-title color-text-plum'>
                      Planet Zeee and the Money Tree
                      <span className='color-text-grapefruit'>.</span>
                    </h2>
                    <p className='book-description'>
                      Take your child on an intergalactic adventure with the
                      kids from Planet Zeee as their Earthling friends teach the
                      fundamentals of financial responsibility and good{' '}
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        money habits.
                      </span>
                    </p>
                    <Button
                      label={'Explore the Book'}
                      type={ButtonConstants.TYPE.INTERNAL}
                      link={'/planet-zeee-and-the-money-tree'}
                      ariaLabel={'Explore Planet Zeee and the Money Tree book'}
                    />
                  </Cell>
                </Grid>
                <Grid className='book-item'>
                  <Cell className='book-image' small={12} large={6}>
                    <img
                      className='book'
                      src={'/img/Book_Emma_And_The_Cosmo_Phone.png'}
                      alt='Emma and the Cosmo Phone book, new release'
                    />
                  </Cell>
                  <Cell className='book-info' small={12} large={6}>
                    <h2 className='book-title color-text-plum'>
                      Emma and
                      <br />
                      the Cosmo Phone
                      <span className='color-text-grapefruit'>.</span>
                    </h2>
                    <p className='book-description'>
                      The Planet Zeee gang is back again, dropping in on their
                      Earthling friends to help Emma on her quest for a fancy
                      new gadget while sharing more smart money lessons{' '}
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                      >
                        for kids.
                      </span>
                    </p>
                    <Button
                      label={'Explore the Book'}
                      type={ButtonConstants.TYPE.INTERNAL}
                      link={'/emma-and-the-cosmo-phone'}
                      ariaLabel={'Explore Emma and the Cosmo Phone book'}
                    />
                  </Cell>
                </Grid>
                <Grid>
                  <Cell
                    className='book-hoverboard'
                    small={12}
                    largePush={5}
                    large={7}
                  >
                    <img
                      src={'/img/LandingPage_BooksModule_Hoverboard.png'}
                      alt='Kids hoverboarding'
                    />
                  </Cell>
                </Grid>
              </div>

              <ARHero
                showCta={true}
                showBleedBackground={false}
                headerElement='h2'
              />

              <div className='volunteerModule'>
                <Grid>
                  <Cell className='volunteer-title' small={12}>
                    <h2 className='color-text-plum'>
                      Learning, saving and{' '}
                      <span className='color-text-grapefruit'>growing.</span>
                    </h2>
                  </Cell>
                  <Cell className='volunteer-description' small={12} large={8}>
                    <p className='text-subhead'>
                      We're committed to helping teachers and parents teach kids
                      the fundamentals of budgeting, smart saving and giving
                      back to their communities.
                    </p>
                  </Cell>
                  <Cell collapse small={12}>
                    <ImageCarousel carouselItems={carouselItems} />
                  </Cell>
                  <Cell className='volunteer-stats' small={12}>
                    <Grid>
                      <Cell
                        className='stat stat-1'
                        small={12}
                        large={4}
                        collapse
                      >
                        <div className='image'>
                          <img
                            src='/img/stat-1.png'
                            alt='Illustration of donated books'
                          />
                        </div>
                        <div className='description'>
                          <div className='stat-title text-large-headline color-text-grapefruit'>
                            139,529
                          </div>
                          <p>books donated for education</p>
                        </div>
                      </Cell>
                      <Cell
                        className='stat stat-2'
                        small={12}
                        large={4}
                        collapse
                      >
                        <div className='image'>
                          <img
                            src='/img/stat-2.png'
                            alt='Illustration of school'
                          />
                        </div>
                        <div className='description'>
                          <div className='stat-title text-large-headline color-text-grapefruit'>
                            71,000+
                          </div>
                          <p>students reached through readings</p>
                        </div>
                      </Cell>
                      <Cell
                        className='stat stat-3'
                        small={12}
                        large={4}
                        collapse
                      >
                        <div className='image'>
                          <img
                            src='/img/stat-3.png'
                            alt='Illustration of reading volunteers'
                          />
                        </div>
                        <div className='description'>
                          <div className='stat-title text-large-headline color-text-grapefruit'>
                            2,477
                          </div>
                          <p>hours of employee volunteered readings</p>
                        </div>
                      </Cell>
                    </Grid>
                  </Cell>
                </Grid>
              </div>
              <div className='tourModule color-background-champagne'>
                <Grid>
                  <Cell className='tour-title' small={12}>
                    <h2 className='color-text-plum'>
                      Build-a-Bank{' '}
                      <span className='color-text-grapefruit'>Tour.</span>
                    </h2>
                  </Cell>
                  <Cell className='tour-image' small={12} large={6}>
                    <img
                      src={'/img/Build_A_Bank_Tour.jpg'}
                      alt='Illustration of Build-a-Bank Tour truck'
                    />
                  </Cell>
                  <Cell className='tour-info' small={12} large={6}>
                    <h3 className='tour-info-title color-text-plum'>
                      About the tour
                      <span className='color-text-grapefruit'>.</span>
                    </h3>
                    <p className='tour-info-description'>
                      CAMP and Ally took to the streets of Detroit, making stops
                      across the city and giving kids and non-profit
                      organizations the opportunity to engage creatively right
                      at our Build-A-Bank truck.
                    </p>
                    <h3 className='tour-info-title color-text-plum'>
                      Missed the tour
                      <span className='color-text-grapefruit'>?</span>
                    </h3>
                    <p className='tour-info-description'>
                      Get our activity book for fun that young allies can enjoy
                      right at home.
                    </p>
                    <div className='tour-info-cta'>
                      <Button
                        label={'Download Now'}
                        type={ButtonConstants.TYPE.DOWNLOAD}
                        link={'/LearningGuide_ActivitiesForYoungAllies.pdf'}
                      />
                    </div>
                  </Cell>
                </Grid>
              </div>
              <div className='videoModule color-background-plum color-text-white'>
                <Grid>
                  <Cell className='video-copy' small={12} large={5}>
                    <h3 className='video-title'>
                      create a toy store in the classroom.
                    </h3>
                    <p className='video-description'>
                      Teachers, watch the virtual CAMP event with your class and
                      have your students apply the lessons they learn as they
                      create their very own toy store. Foundational financial
                      lessons like earning, saving, spending, and needs versus
                      wants, are taught through a read along of Emma and the
                      CosmoPhone with Olympian Simone Manuel.
                    </p>
                    <p className='video-description'>
                      Download the companion activity sheets below and provide
                      your class with fun, interactive and creative challenges
                      that help illustrate basic life skills.
                    </p>
                    <div className='video-cta'>
                      <Button
                        label={'Download Now'}
                        type={ButtonConstants.TYPE.DOWNLOAD}
                        link={'/PZ_ToyStoreActivities.zip'}
                      />
                    </div>
                  </Cell>
                  <Cell className='video-player' small={12} large={7}>
                    <div className='video-container'>
                      <div
                        className={`player-container ${
                          showPlayer ? 'show' : ''
                        }`}
                      >
                        <div
                          className={`player`}
                          ref={playerContainerRef}
                        ></div>
                      </div>
                      <div
                        className={`video-thumbnail ${
                          showPlayer ? 'hide' : ''
                        }`}
                      >
                        <img
                          src={`/img/camp_ally_placeholder.png`}
                          alt='Video of Build-a-bank galaxy jars'
                        />
                      </div>
                      <button
                        className={`play-button color-background-medium-blue ${
                          showPlayer ? 'hide' : ''
                        }`}
                        aria-label='Play video'
                        onClick={playVideo}
                      >
                        <img src={`/img/play.png`} alt='Play' />
                      </button>
                    </div>
                  </Cell>
                </Grid>
                {/* <div className='unicorn-background'></div> */}
              </div>
              <img
                src='http://ally.demdex.net/event?d_sid=21819583'
                width='0'
                height='0'
              />
            </main>
            <Footer showLegalese={true} />
          </Cell>
        </Grid>
      </GridContainer>
    </div>
  );
}
